import { useEffect, useState, useRef } from 'react';
import NextButton from '../NextButton';
import RatingIcon, { RatingHoverIcon } from '../RatingIcon';

export default function RatingQuestion({
	question,
	handleQuestionSubmit,
	lastQuestion,
	isReadOnly = false,
	response = null,
	showNextQuestionButton = true,
	getStartResponseTime,
}) {
	const isRequired = question.config.required;
	const [error, setError] = useState(isRequired);
	const choiceSelected = useRef(false);

	let initialValue = null;

	// Are we rendering an existing response?
	if (response) {
		if (response[question.nanoid]) {
			initialValue = response[question.nanoid];
		}
	}

	const [values, setValues] = useState(initialValue);

	useEffect(() => {
		if (isRequired) {
			values !== null && values !== undefined
				? setError(false)
				: setError(true);
		}
	}, [isRequired, values]);

	const formSubmissionHandler = e => {
		e.preventDefault();

		handleQuestionSubmit({
			question: question,
			answer: values,
		});
	};

	const setRatingQuestionValues = values => {
		if (!choiceSelected.current) {
			choiceSelected.current = true;
			getStartResponseTime(question.nanoid);
		}
		setValues(values);
	};
	return (
		<>
			<form onSubmit={formSubmissionHandler}>
				<div className="flex flex-row gap-2">
					{Array.from({ length: question.config.number_of_shape }, (_, i) => {
						const ratingValue = i + 1;
						return (
							<label
								key={i}
								className="cursor-pointer flex flex-col items-center gap-2"
							>
								<input
									disabled={isReadOnly}
									type="radio"
									name="rating"
									value={ratingValue}
									onClick={() => setRatingQuestionValues(ratingValue)}
									className="hidden"
								/>
								{ratingValue > values ? (
									<RatingIcon
										iconType={question.config.shape}
										className={`text-stone-200 ${
											question.config.shape === 'heart'
												? 'hover:text-red-400'
												: 'hover:text-amber-400'
										}  w-7 h-7`}
									/>
								) : (
									<RatingHoverIcon
										iconType={question.config.shape}
										className={`text-amber-200 ${
											question.config.shape === 'heart'
												? 'fill-red-400'
												: 'fill-amber-400'
										} hover:text-amber-400 w-7 h-7`}
									/>
								)}
								<span className="text-stone-600 text-sm font-mono">
									{i + 1}
								</span>
							</label>
						);
					})}
				</div>

				{showNextQuestionButton && (
					<NextButton disabled={error} lastQuestion={lastQuestion} />
				)}
			</form>
		</>
	);
}
