import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import NextButton from '../NextButton';
import { classNames } from '../utils/utils';

function CharacterCount({ currentValue, maxCharacters }) {
	return (
		<div
			className={classNames(
				currentValue.length > maxCharacters ? 'text-red-600' : 'text-gray-600',
				'text-xs font-medium text-right mt-2',
			)}
		>
			{currentValue.length}/{maxCharacters}
		</div>
	);
}

const TextQuestion = ({
	question,
	handleQuestionSubmit,
	lastQuestion,
	isReadOnly = false,
	response = null,
	showNextQuestionButton = true,
	getStartResponseTime,
}) => {
	const { t } = useTranslation();
	const isRequired = question.config.required;
	const minCharacters = question.config.min_characters;
	const maxCharacters = question.config.max_characters;

	let initialValue = '';

	// Are we rendering an existing response?
	if (response) {
		if (response[question.nanoid]) {
			initialValue = response[question.nanoid];
		}
	}

	const [values, setValues] = useState(initialValue);

	const [error, setError] = useState(isRequired);
	const [message, setMessage] = useState('');
	const [numKeystrokes, setNumKeystrokes] = useState(0);
	const startedTyping = useRef(false);

	const inputChangeHandler = e => {
		if (question.question_type === 'text' && !startedTyping.current) {
			startedTyping.current = true;
			getStartResponseTime(question.nanoid);
		}

		setValues(e.target.value);
	};

	useEffect(() => {
		if (question.question_type === 'description') {
			setError(false);
		} else {
			if (isRequired) {
				values.length >= minCharacters && values.length <= maxCharacters
					? setError(false)
					: setError(true);
			}
			if (values) {
				if (values.length < minCharacters) {
					setMessage(
						t('questionTypes.text.validations.minCharacters', {
							minCharacters: minCharacters,
						}),
					);
				} else if (values.length > maxCharacters) {
					setMessage(
						t('questionTypes.text.validations.maxCharacters', {
							maxCharacters: maxCharacters,
						}),
					);
				} else {
					setMessage(null);
				}
			} // `You must type at least ${minCharacters} characters`
			// `You must type at most ${maxCharacters} characters`
		}
	}, [
		isRequired,
		maxCharacters,
		minCharacters,
		question.question_type,
		t,
		values,
		values.length,
	]);

	const formSubmissionHandler = e => {
		e.preventDefault();

		if (question.question_type === 'description') {
			getStartResponseTime(question.nanoid);
		}

		handleQuestionSubmit({
			question: question,
			answer: values,
			numKeystrokes: numKeystrokes,
		});
	};

	return (
		<div>
			<form onSubmit={formSubmissionHandler}>
				{question.question_type !== 'description' &&
					question.config.is_multiline && (
						<>
							<textarea
								disabled={isReadOnly}
								rows={4}
								className="shadow-sm focus:ring-mint-500 focus:border-mint-500 block w-full sm:text-sm border-gray-300 rounded-md"
								placeholder={
									isReadOnly ? '' : t('questionTypes.text.placeholder')
								}
								onChange={inputChangeHandler}
								onKeyUp={() => setNumKeystrokes(numKeystrokes + 1)}
								value={values}
							/>
							<CharacterCount
								currentValue={values}
								maxCharacters={question.config.max_characters}
							/>
						</>
					)}
				{question.question_type !== 'description' &&
					!question.config.is_multiline && (
						<>
							<input
								disabled={isReadOnly}
								type="text"
								value={values}
								placeholder={
									isReadOnly ? '' : t('questionTypes.text.placeholder')
								}
								className="block w-full shadow-sm focus:ring-mint-500 focus:border-mint-500 sm:text-sm border-gray-300 rounded-md"
								onChange={inputChangeHandler}
								onKeyUp={() => setNumKeystrokes(numKeystrokes + 1)}
							/>
							<CharacterCount
								currentValue={values}
								maxCharacters={question.config.max_characters}
							/>
						</>
					)}
				{message && <p className="p-2 text-xs text-red-500">{message}</p>}
				{showNextQuestionButton && (
					<NextButton disabled={error} lastQuestion={lastQuestion} />
				)}
			</form>
		</div>
	);
};

export default TextQuestion;
