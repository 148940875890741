import { Field, Form, Formik } from 'formik';
import { useState, useRef } from 'react';
import NextButton from '../NextButton';
import { classNames } from '../utils/utils';

export default function MatrixQuestion({
	question,
	handleQuestionSubmit,
	lastQuestion,
	isReadOnly = false,
	response = null,
	showNextQuestionButton = true,
	getStartResponseTime,
}) {
	const isRequired = question.config.required;
	const isMultipleSelection = question.config.is_multiselection;
	const [error, setError] = useState(isRequired);
	const choiceSelected = useRef(false);

	const initialValues = {};

	// Are we rendering an existing response?
	if (response) {
		if (response[question.nanoid]) {
			response[question.nanoid].forEach(row => {
				if (isMultipleSelection) {
					initialValues[row.choice] = [];
					row.columns.forEach(column => {
						initialValues[row.choice].push(column.choice);
					});
				} else {
					initialValues[row.choice] = row.columns[0].choice;
				}
			});
		}
	}

	const validate = values => {
		if (!choiceSelected.current) {
			choiceSelected.current = true;
			getStartResponseTime(question.nanoid);
		}
		const inputValues = values;

		if (isRequired) {
			if (inputValues) {
				if (isMultipleSelection) {
					if (
						Object.keys(inputValues).length !== question.rows.length ||
						Object.values(inputValues).find(value => value.length === 0)
					) {
						setError(true);
					} else {
						setError(false);
					}
				}
				if (!isMultipleSelection) {
					Object.keys(inputValues).length !== question.rows.length
						? setError(true)
						: setError(false);
				}
			} else {
				setError(true);
			}
		} else {
			if (Object.keys(inputValues).length > 0) {
				if (isMultipleSelection) {
					if (
						Object.keys(inputValues).length !== question.rows.length ||
						Object.values(inputValues).find(value => value.length === 0)
					) {
						setError(true);
					} else {
						setError(false);
					}
				}
				if (!isMultipleSelection) {
					Object.keys(inputValues).length !== question.rows.length
						? setError(true)
						: setError(false);
				}
			} else {
				Object.keys(inputValues).length === 0 && setError(false);
			}
		}
	};

	const formSubmissionHandler = values => {
		let entries = Object.entries(values);
		let res = entries.map(entry => {
			// If multiple selection is not enabled, Formik returns a `string `here instead of an `Array`. We fix this if necessary.
			let columnsValue = entry[1];
			if (typeof entry[1] === 'string') {
				columnsValue = [
					{
						choice: entry[1],
					},
				];
			} else {
				columnsValue = entry[1].map(c => {
					return {
						choice: c,
					};
				});
			}

			return {
				choice: entry[0],
				columns: columnsValue,
			};
		});

		handleQuestionSubmit({
			question: question,
			answer: res,
		});
	};

	const disableInputFields = (values, rowNanoId, colNanoId) => {
		if (question.nanoid === 'jEtLRxmkGYpmV3MQ') {
			if (!values[rowNanoId]) {
				return false;
			}
			if (values[rowNanoId] && values[rowNanoId].length < 2) {
				return false;
			}

			return !values[rowNanoId].includes(colNanoId);
		} else {
			return false;
		}
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				enableReinitialize={true}
				onSubmit={(values, { resetForm }) => {
					formSubmissionHandler(values);
					resetForm();
				}}
				validate={validate}
			>
				{({ values }) => (
					<Form>
						<div className="mt-3 flex flex-col">
							<div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
								<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
									<div className="w-full overflow-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
										<table className="min-w-full divide-y divide-gray-300">
											<thead className="bg-gray-50">
												<tr className="divide-x divide-gray-200">
													<th scope="col">&nbsp;</th>
													{question.columns.map((column, index) => (
														<th
															key={column.nanoid}
															scope="col"
															className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6 group"
														>
															<div className="flex">
																<span className="pl-0 block w-full border-0 border-b border-transparent bg-transparent focus:outline-none focus:border-mint-600 focus:ring-0 sm:text-sm">
																	{question.columns[index].title}
																</span>
															</div>
														</th>
													))}
												</tr>
											</thead>
											<tbody className="divide-y divide-gray-200 bg-white">
												{question.rows.map((row, index) => (
													<tr
														key={row.nanoid}
														className="divide-x divide-gray-200"
													>
														{question.rows[index].media !== null ? (
															<td
																style={{
																	backgroundImage: `url(${question.rows[index].media})`,
																	backgroundSize: '110px 110px',
																	backgroundRepeat: 'no-repeat',
																}}
																className="whitespace-wrap py-4 pl-4 pr-4 block h-28 w-28 text-sm font-medium text-gray-900 sm:pl-6 group"
															></td>
														) : (
															<td className="whitespace-wrap py-4 pl-4 pr-4 block text-sm font-medium text-gray-900 sm:pl-6 group">
																<div className="flex">
																	<span className="pl-0 block w-full border-0 border-b border-transparent focus:outline-none focus:border-mint-600 focus:ring-0 sm:text-sm">
																		{question.rows[index].title}
																	</span>
																</div>
															</td>
														)}
														{question.columns.map(column => (
															<td
																key={column.nanoid}
																className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6 text-center"
															>
																{
																	<Field
																		disabled={
																			isReadOnly ||
																			disableInputFields(
																				values,
																				row.nanoid,
																				column.nanoid,
																			)
																		}
																		type={
																			isMultipleSelection ? 'checkbox' : 'radio'
																		}
																		name={row.nanoid}
																		value={column.nanoid}
																		className={classNames(
																			isMultipleSelection ? 'rounded' : '',
																			'h-4 w-4 border-gray-300 text-mint-600 focus:ring-mint-500 cursor-pointer',
																		)}
																	/>
																}
															</td>
														))}
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						{showNextQuestionButton && (
							<NextButton disabled={error} lastQuestion={lastQuestion} />
						)}
					</Form>
				)}
			</Formik>
		</>
	);
}
