import React, { useEffect, useState, useReducer } from 'react';
import FileUploadArea from './FileUploadArea';
import MultipleSelectionQuestion from './MultipleSelectionQuestion';
import SingleSelectionQuestion from './SingleSelectionQuestion';
import TextQuestion from './TextQuestion';
import YesNoQuestion from './YesNoQuestion';
import NumberSelectionQuestion from './NumberSelectionQuestion';
import RankingQuestion from './RankingQuestion';
import OpinionScaleQuestion from './OpinionScaleQuestion';
import RatingQuestion from './RatingQuestion';
import MatrixQuestion from './MatrixQuestion';
import logicOperator from './utils/logicOperator';
import { cloneDeep, map, shuffle } from 'lodash';
import Spinner2 from '../Spinner2';
import Alert from '../Alert';
import { Trans } from 'react-i18next';
import QuestionHints from './QuestionHints';
import ReactPlayer from 'react-player';
import { displayMentionIfExists, getQuestionTitle } from './utils/utils';

function measurementReducer(state, action) {
	switch (action.type) {
		case 'startQuestionnaireTime':
			return {
				...state,
				questionnaire: {
					start: new Date(),
					end: undefined,
					total: undefined,
				},
			};
		case 'endQuestionnaireTime': {
			const end = new Date();
			return {
				...state,
				questionnaire: {
					...state.questionnaire,
					end: end,
					total: end - state.questionnaire.start, // ms,
				},
			};
		}
		case 'startQuestionTime':
			return {
				...state,
				questions: {
					...state.questions,
					[action.payload.question]: {
						start: new Date(),
						firstReaction: undefined,
						end: undefined,
						total: undefined,
						totalUntilFirstReaction: undefined,
					},
				},
			};
		case 'startQuestionResponseTime': {
			const reactionStart = new Date();
			return {
				...state,
				questions: {
					...state.questions,
					[action.payload.question]: {
						start: state.questions[action.payload.question].start,
						firstReaction: reactionStart,
						end: undefined,
						total: undefined,
						totalUntilFirstReaction:
							reactionStart - state.questions[action.payload.question].start,
					},
				},
			};
		}
		case 'endQuestionTime': {
			const end = new Date();
			return {
				...state,
				questions: {
					...state.questions,
					[action.payload.question]: {
						start: new Date(),
						firstReaction:
							state.questions[action.payload.question].firstReaction,
						end: end,
						total: end - state.questions[action.payload.question].start,
						numKeystrokes: action.payload.numKeystrokes,
						totalUntilFirstReaction:
							state.questions[action.payload.question].totalUntilFirstReaction,
					},
				},
			};
		}
	}
}

const initialMeasurementState = {
	questionnaire: {
		start: null,
		end: null,
		total: null,
	},
	questions: {},
};

function setCharAt(str, index, char) {
	return str.substring(0, index) + char + str.substring(index + 1);
}

const unileverShuffle = Math.round(Math.random()) === 1;

export default function Questionnaire({
	questionnaireData,
	onSubmitQuestionnaire,
	denebunuDemographics,
	activeQuestionNanoId = null,
	currentMode = 'loading',
	response = null,
}) {
	const [measurementState, dispatchMeasurement] = useReducer(
		measurementReducer,
		initialMeasurementState,
	);
	const [questionList, setQuestionList] = useState([]);
	const [answers, setAnswers] = useState({}); // {questionNanoId: answerValue}
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
	const [currentQuestion, setCurrentQuestion] = useState(null);
	const [doEndQuestionnaire, setDoEndQuestionnaire] = useState(false);

	const goToQuestion = targetQuestionNanoId => {
		const foundIndex = questionList.findIndex(_question => {
			return _question.nanoid === targetQuestionNanoId;
		});

		setCurrentQuestionIndex(foundIndex);
		setCurrentQuestion(questionList[foundIndex]);
	};

	const endSurvey = () => {
		dispatchMeasurement({ type: 'endQuestionnaireTime' });
		setDoEndQuestionnaire(true);
	};

	const getStartResponseTime = questionNanoId => {
		dispatchMeasurement({
			type: 'startQuestionResponseTime',
			payload: {
				question: questionNanoId,
			},
		});
	};

	useEffect(() => {
		let finalQuestionList = [];

		if (questionnaireData.has_screening) {
			let screeningQuestionList = [];
			let generalQuestionList = [];
			questionnaireData.questions.map(question => {
				question.is_screening
					? screeningQuestionList.push(question)
					: generalQuestionList.push(question);
			});

			if (questionnaireData.is_randomized) {
				generalQuestionList = shuffle(generalQuestionList);
			}

			// start new project a0wOwd68lQWlXWZG
			if (generalQuestionList[0].nanoid === 'XOc3BDsLnQ942O1k') {
				generalQuestionList = generalQuestionList.map((question, index) => {
					if (index === 0) {
						let choices = question.choices;

						if (denebunuDemographics.gender == 2) {
							choices = question.choices.slice(4);
						} else {
							choices = question.choices.slice(0, 4);
							choices.push(question.choices[8]);
						}
						return { ...question, choices: choices };
					}
					if (index === 4) {
						let choices = question.choices.map((choice, index) => {
							if (index > 0) {
								const prefix = denebunuDemographics.gender == 2 ? 'K' : 'E';
								const src = `/assets/images/claim/${prefix}${index}.png`;
								return { ...choice, media: src };
							}
							return choice;
						});

						return { ...question, choices: choices };
					}

					return question;
				});
			}

			//end new project a0wOwd68lQWlXWZG

			//start   project id: HFR-1inavZVTBqqi Unilever roll on ambalaj araştırması
			if (generalQuestionList[0].nanoid === 'Gt7vP1BGJ9cSwyt1') {
				generalQuestionList = generalQuestionList.map((question, index) => {
					if (index === 0) {
						const deoWomen = ['Rexona', 'Emotion', 'Nivea', 'Dove'];
						const deoMen = ['Rexona Men', 'AXE', 'Nivea Men', 'Blade'];

						const commonChoices = [];
						question.choices.forEach(choice => {
							if (choice.title === 'FA') {
								if (!commonChoices.find(c => c.title === 'FA')) {
									commonChoices.push(choice);
								}
							}
							if (choice.title === 'Diğer') {
								commonChoices.push(choice);
							}
						});

						let choicesForWomen = question.choices.filter(choice =>
							deoWomen.includes(choice.title),
						);

						choicesForWomen.push(...commonChoices);

						let choicesForMen = question.choices.filter(choice =>
							deoMen.includes(choice.title),
						);

						choicesForMen.push(...commonChoices);

						return {
							...question,
							choices:
								denebunuDemographics.gender == 2
									? choicesForWomen
									: choicesForMen,
						};
					}
					if (index === 6 || index === 9) {
						const womanTitle1 =
							'Kadınlara dış görünüşleri ile ilgili daha iyi hissetmeleri için ilham veren markalardır';
						const manTitle1 =
							'Erkekleri, kendilerini ve diğer kişileri önemsemeleri konusunda cesaretlendiren markalardır';
						const womanTitle2 = 'Modern/Günümüze uygun markalardır';
						const manTitle2 = 'Son moda / Havalı markalardır';
						const newCols = question.columns.map(column => {
							if (column.title === womanTitle1) {
								return {
									...column,
									title:
										denebunuDemographics.gender == 2 ? womanTitle1 : manTitle1,
								};
							}
							if (column.title === womanTitle2) {
								return {
									...column,
									title:
										denebunuDemographics.gender == 2 ? womanTitle2 : manTitle2,
								};
							}
							return column;
						});

						return { ...question, columns: newCols };
					}
					return question;
				});

				generalQuestionList = generalQuestionList.map(question => {
					if (question.question_type === 'matrix') {
						const imageWomen = ['BK1', 'BK2', 'BK3', 'MK1', 'MK2', 'MK3'];

						const newRows = question.rows
							.filter(row =>
								denebunuDemographics.gender == 2
									? imageWomen.includes(row.title)
									: !imageWomen.includes(row.title),
							)
							.map(row => {
								const src = `/assets/images/matrix/${row.title}.jpeg`;
								return { ...row, media: src };
							});
						return { ...question, rows: newRows };
					}

					return question;
				});
			}

			//end   project id: HFR-1inavZVTBqqi Unilever roll on ambalaj araştırması

			finalQuestionList = screeningQuestionList.concat(generalQuestionList);
		} else {
			finalQuestionList = questionnaireData.questions;
			if (questionnaireData.is_randomized) {
				finalQuestionList = shuffle(finalQuestionList);
			} else {
				if (finalQuestionList[0].nanoid === 'x3jiosJ8hSTJmJva') {
					if (unileverShuffle) {
						const video1Index = finalQuestionList.findIndex(
							item => item['nanoid'] === '80IRmxTVgSgkWJWZ',
						);

						const video2Index = finalQuestionList.findIndex(
							item => item['nanoid'] === 'k3dfX8g7P8hfEogr',
						);

						// Swap the first items
						let temp = finalQuestionList[video1Index];
						finalQuestionList[video1Index] = finalQuestionList[video2Index];
						finalQuestionList[video2Index] = temp;

						// Swap the consecutive items
						temp = finalQuestionList[video1Index + 1];
						finalQuestionList[video1Index + 1] =
							finalQuestionList[video2Index + 1];
						finalQuestionList[video2Index + 1] = temp;

						const abc = cloneDeep(finalQuestionList);

						abc[video1Index]['title'] = setCharAt(
							abc[video1Index]['title'],
							6,
							'1',
						);
						abc[video2Index]['title'] = setCharAt(
							abc[video2Index]['title'],
							6,
							'2',
						);

						finalQuestionList = abc;
					}
				}
			}

			//*************************** */

			//************************** */
		}

		setQuestionList(finalQuestionList);

		let questionIndexToSet = 0;

		//Start from the first question if activeQuestionNanoId is not set

		if (activeQuestionNanoId) {
			const foundIndex = finalQuestionList.findIndex(_question => {
				return _question.nanoid === activeQuestionNanoId;
			});

			if (foundIndex > -1) {
				questionIndexToSet = foundIndex;
			}
		}

		setCurrentQuestion(finalQuestionList[questionIndexToSet]);
		setCurrentQuestionIndex(questionIndexToSet);
	}, [questionnaireData, activeQuestionNanoId, denebunuDemographics]);

	const goToNextQuestion = () => {
		if (currentQuestionIndex === questionList.length - 1) {
			endSurvey();
		} else {
			setCurrentQuestionIndex(currentQuestionIndex + 1);
			setCurrentQuestion(questionList[currentQuestionIndex + 1]);
		}
	};

	useEffect(() => {
		if (doEndQuestionnaire) {
			const formattedAnswers = map(answers, (answer, question) => {
				return {
					question: question,
					response: answer,
				};
			});

			const measurementData = {
				total_time: measurementState.questionnaire.total,
				question_measurements: Object.keys(measurementState.questions).map(
					questionNanoId => {
						return {
							question: questionNanoId,
							total_time: measurementState.questions[questionNanoId].total,
							time_to_first_reaction:
								measurementState.questions[questionNanoId]
									.totalUntilFirstReaction,
							num_keystrokes: measurementState.questions[questionNanoId]
								.numKeystrokes
								? measurementState.questions[questionNanoId].numKeystrokes
								: null,
						};
					},
				),
			};

			onSubmitQuestionnaire(formattedAnswers, measurementData);
			setDoEndQuestionnaire(false);
		}
	}, [doEndQuestionnaire, onSubmitQuestionnaire, answers, measurementState]);

	const handleQuestionSubmit = ({
		question,
		answer,
		numKeystrokes = null,
		otherAnswerValue = null,
	}) => {
		/*
		 * Store question answers in state
		 * Route to next question or complete
		 */

		let a = answer;

		if (question.nanoid === 'tbZqfwsKMi4VFSF0' && unileverShuffle) {
			const video1ChoiceNanoId = 'JWFnp6kzJHu-rtoq';
			const video2ChoiceNanoId = 'ycbh0iRL7OHcs1yZ';
			if (answer === video1ChoiceNanoId) {
				a = video2ChoiceNanoId;
			} else {
				a = video1ChoiceNanoId;
			}
		}

		const newAnswers = {
			...answers,
			[question.nanoid]: a,
		};

		if (otherAnswerValue) {
			newAnswers[otherAnswerValue.question.nanoid] = otherAnswerValue.answer;
		}

		dispatchMeasurement({
			type: 'endQuestionTime',
			payload: { question: question.nanoid, numKeystrokes: numKeystrokes },
		});

		setAnswers(newAnswers);

		if (question.is_screening) {
			let continueQuestionnaire = false;
			if (question.question_type === 'multiple_selection') {
				if (question.nanoid === 'LO4zO-3yqfincL4H') {
					const selectedAnswers = answer.filter(
						answer =>
							question.choices.find(choice => choice.nanoid === answer)
								.is_qualified,
					);
					continueQuestionnaire = selectedAnswers.length >= 2;
				} else {
					continueQuestionnaire = answer.some(
						answr =>
							question.choices.find(choice => choice.nanoid === answr)
								.is_qualified,
					);
				}
			} else {
				continueQuestionnaire = question.choices.find(
					choice => choice.nanoid === answer,
				).is_qualified;
			}

			if (continueQuestionnaire) {
				goToNextQuestion(answer);
			} else {
				endSurvey();
			}

			return;
		}

		if (question.logic.rules.length > 0) {
			const logicResult = logicOperator(question, questionList, newAnswers);

			switch (logicResult.action) {
				case 'go_to_next_question':
					goToNextQuestion();
					break;
				case 'go_to_question':
					goToQuestion(logicResult.question);
					break;
				case 'end_survey':
					endSurvey();
					break;
				default:
					break;
			}
		} else {
			goToNextQuestion(answer);
		}
	};

	useEffect(() => {
		dispatchMeasurement({ type: 'startQuestionnaireTime' });
	}, []);

	useEffect(() => {
		if (currentQuestion) {
			dispatchMeasurement({
				type: 'startQuestionTime',
				payload: {
					question: currentQuestion.nanoid,
				},
			});
		}
	}, [currentQuestion]);

	const lastQuestion = currentQuestionIndex === questionList.length - 1;

	const renderPreview = () => {
		if (!currentQuestion) {
			return <></>;
		}

		switch (currentQuestion.question_type) {
			case 'single_selection':
				return (
					<SingleSelectionQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						handleQuestionSubmit={handleQuestionSubmit}
						denebunuDemographics={denebunuDemographics}
						questionList={questionList}
						lastQuestion={lastQuestion}
						response={response}
						getStartResponseTime={getStartResponseTime}
					/>
				);
			case 'multiple_selection':
				return (
					<MultipleSelectionQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						handleQuestionSubmit={handleQuestionSubmit}
						questionList={questionList}
						canReorder={false}
						lastQuestion={lastQuestion}
						response={response}
						getStartResponseTime={getStartResponseTime}
					/>
				);
			case 'yes_no':
				return (
					<YesNoQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						handleQuestionSubmit={handleQuestionSubmit}
						questionList={questionList}
						lastQuestion={lastQuestion}
						response={response}
						getStartResponseTime={getStartResponseTime}
					/>
				);
			case 'text':
				return (
					<TextQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						handleQuestionSubmit={handleQuestionSubmit}
						questionList={questionList}
						lastQuestion={lastQuestion}
						response={response}
						getStartResponseTime={getStartResponseTime}
					/>
				);
			case 'description':
				return (
					<TextQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						handleQuestionSubmit={handleQuestionSubmit}
						lastQuestion={lastQuestion}
						getStartResponseTime={getStartResponseTime}
					/>
				);
			case 'number':
				return (
					<NumberSelectionQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						handleQuestionSubmit={handleQuestionSubmit}
						questionList={questionList}
						lastQuestion={lastQuestion}
						response={response}
						getStartResponseTime={getStartResponseTime}
					/>
				);
			case 'rating':
				return (
					<RatingQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						handleQuestionSubmit={handleQuestionSubmit}
						questionList={questionList}
						lastQuestion={lastQuestion}
						response={response}
						getStartResponseTime={getStartResponseTime}
					/>
				);
			case 'opinion_scale':
				return (
					<OpinionScaleQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						handleQuestionSubmit={handleQuestionSubmit}
						lastQuestion={lastQuestion}
						response={response}
						getStartResponseTime={getStartResponseTime}
					/>
				);
			case 'nps':
				return (
					<OpinionScaleQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						handleQuestionSubmit={handleQuestionSubmit}
						lastQuestion={lastQuestion}
						response={response}
						getStartResponseTime={getStartResponseTime}
					/>
				);
			case 'file_upload':
				return (
					<FileUploadArea
						key={currentQuestion.nanoid}
						question={currentQuestion}
						handleQuestionSubmit={handleQuestionSubmit}
						lastQuestion={lastQuestion}
						response={response}
						getStartResponseTime={getStartResponseTime}
					/>
				);
			case 'ranking':
				return (
					<RankingQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						handleQuestionSubmit={handleQuestionSubmit}
						denebunuDemographics={denebunuDemographics}
						questionList={questionList}
						lastQuestion={lastQuestion}
						response={response}
						getStartResponseTime={getStartResponseTime}
					/>
				);
			case 'matrix':
				return (
					<MatrixQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						handleQuestionSubmit={handleQuestionSubmit}
						questionList={questionList}
						lastQuestion={lastQuestion}
						response={response}
						getStartResponseTime={getStartResponseTime}
					/>
				);
			default:
				return <></>;
		}
	};

	if (currentMode === 'loading') {
		return <Spinner2 className="mx-auto w-8 h-8 text-mint-600" />;
	}

	return (
		<>
			<div className="w-full bg-white shadow border border-gray-200 sm:overflow-hidden sm:rounded-md">
				<div className="">
					<div className="flex flex-row gap-3 items-center px-6 py-4">
						<div className="flex-shrink-0 rounded border bg-mint-100 border-mint-700 text-mint-700 h-12 w-12 text-center text-xl font-bold grid place-content-center ">
							<div>{currentQuestionIndex + 1}</div>
						</div>
						<div className="flex flex-col flex-wrap sm:flex-nowrap">
							<h3 className="text-lg font-medium leading-6 text-gray-800">
								{currentQuestion &&
									Object.keys(answers).length > 0 &&
									displayMentionIfExists(
										currentQuestion.title,
										answers,
										questionList,
									)}
								{currentQuestion &&
									Object.keys(answers).length === 0 &&
									getQuestionTitle(currentQuestion.title, questionList)}
							</h3>
							{currentQuestion && currentQuestion.description && (
								<p className="mt-1 text-xs text-gray-500">
									{currentQuestion.description}
								</p>
							)}
						</div>
					</div>
				</div>
				<div className="flex flex-col overflow-y-auto">
					<div>
						<div className="inline-block min-w-full">
							<div className="border-t border-gray-200">
								<div>
									{currentMode === 'error' && (
										<Alert type={'error'}>
											<Trans>
												An unexpected error occurred. Your response could not be
												saved. Please try again in a few minutes.
											</Trans>
										</Alert>
									)}
									{currentMode === 'thank_you' && (
										<Alert type={'success'}>
											<Trans>Thank you! Your response has been saved.</Trans>
										</Alert>
									)}

									{currentMode === 'question' && currentQuestion && (
										<div className="px-6 py-4">
											<div className="flex flex-col gap-4">
												{currentQuestion.media &&
													(currentQuestion.nanoid === 'wR94QgFDfwNQrC59' ||
														currentQuestion.nanoid === 'EcgR2Kufv68yhAqB') && (
														<img
															className="rounded"
															src={currentQuestion.media}
														/>
													)}
												{currentQuestion.media &&
													currentQuestion.media.media_type === 'image' && (
														<img
															className="rounded"
															src={currentQuestion.media.url}
														/>
													)}
												{currentQuestion.media &&
													currentQuestion.media.media_type === 'video' && (
														<ReactPlayer
															controls={true}
															url={currentQuestion.media.url}
															width={'100%'}
															height={'100%'}
														/>
													)}
												<QuestionHints question={currentQuestion} />
												{renderPreview()}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
