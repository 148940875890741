import { useState, useEffect } from 'react';
import Questionnaire from './components/Questionnaire';
import i18n from './i18n';
import queryString from 'query-string';
import { sendParentWindowMessage, isMessageAuthentic } from './Messaging';

export default function App() {
	const [questionnaireData, setQuestionnaireData] = useState(null);
	const [denebunuDemographics, setDenebunuDemographics] = useState(null);
	const [currentMode, setCurrentMode] = useState('loading');

	useEffect(() => {
		// Get language from query string and set it
		const queryParams = queryString.parse(window.location.search);
		const language = queryParams.lang ? queryParams.lang : 'tr'; // Defaults to Turkish
		i18n.changeLanguage(language);

		function onMessageReceived(message) {
			if (isMessageAuthentic(message)) {
				if (message.data.action === 'renderQuestionnaire') {
					setCurrentMode('question');
					setDenebunuDemographics(message.data.data.denebunuDemographics);
					setQuestionnaireData(message.data.data.questionnaire);
				} else if (message.data.action === 'saveSucceeded') {
					setCurrentMode('thank_you');
				} else if (message.data.action === 'saveFailed') {
					setCurrentMode('error');
				}
			}
		}

		window.addEventListener('message', onMessageReceived);

		sendParentWindowMessage({ action: 'ready' });
	}, []);

	const handleSubmit = (response, measurementData) => {
		setCurrentMode('loading');
		sendParentWindowMessage({
			action: 'completeQuestionnaire',
			data: {
				response: response,
				measurement_data: measurementData,
			},
		});
	};

	return (
		<>
			{questionnaireData && (
				<Questionnaire
					questionnaireData={questionnaireData}
					onSubmitQuestionnaire={handleSubmit}
					currentMode={currentMode}
					denebunuDemographics={denebunuDemographics}
				/>
			)}
		</>
	);
}
