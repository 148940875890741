import { useEffect, useState, useReducer, useRef } from 'react';
import NextButton from '../NextButton';
import { cloneDeep, findIndex, indexOf, countBy } from 'lodash';
import { scaleBase64Image } from '../utils/utils';
import Alert from '../../Alert';
import File from '../File';
import { v4 as uuidv4 } from 'uuid';
import {
	isMessageAuthentic,
	sendParentWindowMessage,
} from '../../../Messaging';
import { Trans, useTranslation } from 'react-i18next';

const allowedFileMimes = ['image/jpeg', 'image/png', 'application/pdf'];

const initialState = {
	uploadedFileCount: 0,
	incompleteFileCount: 0, // Upload error etc.
	values: [],
};

function calculateStateCounts(values) {
	const countResult = countBy(values, 'status');

	const uploadedFileCount = countResult['uploaded']
		? countResult['uploaded']
		: 0;
	const incompleteFileCount = values.length - uploadedFileCount;

	return {
		uploadedFileCount: uploadedFileCount,
		incompleteFileCount: incompleteFileCount,
	};
}

function reducer(state, action) {
	switch (action.type) {
		case 'addFile': {
			const newValues = cloneDeep(state.values);

			newValues.push({
				internalId: action.payload.internalId,
				thumbnail: action.payload.thumbnail,
				original: action.payload.original,
				status: 'uploading',
			});

			const counts = calculateStateCounts(newValues);

			return {
				...counts,
				values: newValues,
			};
		}
		case 'removeFile': {
			const newValues = cloneDeep(state.values);

			const foundIndex = findIndex(
				newValues,
				item => item.internalId === action.payload.internalId,
			);

			newValues.splice(foundIndex, 1);

			const counts = calculateStateCounts(newValues);

			return {
				...counts,
				values: newValues,
			};
		}
		case 'setFileStatus': {
			const newValues = cloneDeep(state.values);
			const foundIndex = findIndex(
				newValues,
				item => item.internalId === action.payload.internalId,
			);
			newValues[foundIndex]['status'] = action.payload.status;

			const counts = calculateStateCounts(newValues);

			return {
				...counts,
				values: newValues,
			};
		}
		case 'setFileUrl': {
			const newValues = cloneDeep(state.values);
			const foundIndex = findIndex(
				newValues,
				item => item.internalId === action.payload.internalId,
			);
			newValues[foundIndex]['status'] = 'uploaded';
			newValues[foundIndex]['id'] = action.payload.id;
			newValues[foundIndex]['url'] = action.payload.url;

			const counts = calculateStateCounts(newValues);

			return {
				...counts,
				values: newValues,
			};
		}
		/* This action is used when we're rendering an existing response where we need to add a bunch of files at once /*/
		case 'importFiles': {
			const newValues = cloneDeep(state.values);

			action.payload.files.forEach(file => {
				newValues.push({
					internalId: uuidv4(),
					thumbnail: file.thumbnail,
					original: file.original,
					status: 'uploaded',
				});
			});

			const counts = calculateStateCounts(newValues);

			return {
				...counts,
				values: newValues,
			};
		}
		case 'resetState': {
			return initialState;
		}

		default:
			throw new Error();
	}
}

export default function FileUploadArea({
	question,
	handleQuestionSubmit,
	lastQuestion,
	isReadOnly = false,
	response = null,
	showNextQuestionButton = true,
	getStartResponseTime,
}) {
	const fileUploadFieldRef = useRef(null);
	const [error, setError] = useState(question.config.required);
	const [message, setMessage] = useState('');
	const { t } = useTranslation();
	const fileUploaded = useRef(false);

	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		// Are we rendering an existing response?
		if (response) {
			if (response[question.nanoid]) {
				dispatch({
					type: 'resetState',
				});

				const files = [];

				response[question.nanoid].forEach(file => {
					files.push({
						original: file.file_url,
						thumbnail: file.file_thumbnail,
					});
				});

				dispatch({
					type: 'importFiles',
					payload: { files: files },
				});
			}
		}
	}, [question.nanoid, response]);

	useEffect(() => {
		function onMessageReceived(message) {
			if (isMessageAuthentic(message)) {
				const messageData = message.data.data;

				if (messageData.questionNanoId === question.nanoid) {
					if (message.data.action === 'fileUploaded') {
						dispatch({
							type: 'setFileUrl',
							payload: {
								internalId: messageData.reference,
								id: messageData.id,
								url: messageData.url,
							},
						});
					} else if (message.data.action === 'fileUploadFailed') {
						dispatch({
							type: 'setFileStatus',
							payload: {
								internalId: messageData.reference,
								status: 'error',
							},
						});
					}
				}
			}
		}
		window.removeEventListener('message', onMessageReceived);
		window.addEventListener('message', onMessageReceived);
	}, [question.nanoid]);

	useEffect(() => {
		if (question.config.required) {
			if (state.incompleteFileCount > 0) {
				setError(true);
			} else {
				if (
					state.uploadedFileCount >= question.config.min_file_count &&
					state.uploadedFileCount <= question.config.max_file_count
				) {
					setError(false);
				} else {
					setError(true);
				}
			}
		} else {
			state.incompleteFileCount > 0 ? setError(true) : setError(false);
		}
	}, [
		state.uploadedFileCount,
		state.incompleteFileCount,
		state.values,
		question.config,
	]);

	const handleRemove = internalId => {
		dispatch({
			type: 'removeFile',
			payload: {
				internalId: internalId,
			},
		});
	};

	const handleImage = event => {
		setMessage('');
		if (!fileUploaded.current) {
			fileUploaded.current = true;
			getStartResponseTime(question.nanoid);
		}

		const maxFileSize = 7 * 1024 * 1024;

		if (event.target.files[0].size > maxFileSize) {
			setMessage(t('fileUploadError.fileTooLarge'));
			event.target.value = null;
			return;
		}

		const fileType = event.target.files[0].type;

		if (indexOf(allowedFileMimes, event.target.files[0].type) === -1) {
			setMessage(t('fileUploadError.wrongFormat'));
			event.target.value = null;
			return;
		}

		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);

		event.target.value = null;

		const internalId = uuidv4();

		reader.onload = () => {
			scaleBase64Image(reader.result, 400, 350).then(resizedImageBase64 => {
				dispatch({
					type: 'addFile',
					payload: {
						internalId: internalId,
						thumbnail: resizedImageBase64,
						original: reader.result,
					},
				});

				sendParentWindowMessage({
					action: 'uploadFile',
					questionNanoId: question.nanoid,
					reference: internalId,
					fileType: fileType,
					fileData: reader.result,
				});
			});
		};

		reader.onerror = () => {
			dispatch({
				type: 'setFileStatus',
				payload: {
					internalId: internalId,
					status: 'error',
				},
			});
		};
	};

	const formSubmissionHandler = e => {
		e.preventDefault();

		const submitValues = state.values.map(item => ({
			id: item.id,
			url: item.url,
		}));

		dispatch({
			type: 'resetState',
		});

		handleQuestionSubmit({
			question: question,
			answer: submitValues,
		});
	};

	return (
		<form onSubmit={formSubmissionHandler}>
			<input
				ref={fileUploadFieldRef}
				type="file"
				className="sr-only hidden"
				onChange={handleImage}
			/>
			{message && (
				<Alert type={'error'} className="mb-4">
					{message}
				</Alert>
			)}
			<div className="px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
				{state.values.length > 0 && (
					<>
						<div className="flex flex-row flex-wrap gap-4 items-center">
							{state.values.map((file, index) => (
								<File
									key={index}
									file={file}
									handleRemove={handleRemove}
									isReadOnly={isReadOnly}
								/>
							))}
						</div>
						{!isReadOnly && (
							<button
								type="button"
								className="mt-4 disabled:cursor-not-allowed disabled:hover:bg-mint-600 disabled:opacity-30 items-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-mint-600 hover:bg-mint-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mint-500"
								onClick={() => {
									fileUploadFieldRef.current.click();
								}}
							>
								<div className="inline-flex px-4 py-2">
									<Trans>Add File</Trans>
								</div>
							</button>
						)}
					</>
				)}
				{state.values.length === 0 && (
					<div className="flex-auto">
						<div className=" mx-auto space-y-1 text-center">
							<svg
								className="mx-auto h-12 w-12 text-gray-400"
								stroke="currentColor"
								fill="none"
								viewBox="0 0 48 48"
								aria-hidden="true"
							>
								<path
									d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
									strokeWidth={2}
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							{!isReadOnly && (
								<div className="text-sm text-gray-600">
									<label
										htmlFor="file-upload"
										className="relative cursor-pointer bg-white rounded-md font-medium text-mint-600 hover:text-mint-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-mint-500"
										onClick={() => {
											fileUploadFieldRef.current.click();
										}}
									>
										<span>
											<Trans>Upload a file</Trans>
										</span>
									</label>
								</div>
							)}
							<p className="text-xs text-gray-500">
								<Trans>PNG, JPG or PDF up to 7MB</Trans>
							</p>
						</div>
					</div>
				)}
			</div>

			{showNextQuestionButton && (
				<NextButton disabled={error} lastQuestion={lastQuestion} />
			)}
		</form>
	);
}
