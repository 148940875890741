import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { indexOf } from 'lodash';
import { useTranslation } from 'react-i18next';

const eligibleQuestionTypes = [
	'multiple_selection',
	'text',
	'number',
	'file_upload',
];

export default function QuestionHints({ question }) {
	const { t } = useTranslation();

	if (indexOf(eligibleQuestionTypes, question.question_type) === -1) {
		return <></>;
	}

	switch (question.question_type) {
		case 'multiple_selection':
			if (
				!(
					question.config.selection_count_type === 'exact_number' ||
					question.config.selection_count_type === 'range'
				)
			) {
				return <></>;
			}
			break;
		case 'text':
			if (question.config.min_characters < 2) {
				return <></>;
			}
			break;
		case 'number':
			if (!question.config.is_custom_range) {
				return <></>;
			}
			break;
	}

	return (
		<div className="flex gap-1 items-center">
			<InformationCircleIcon className="w-5 h-5 text-gray-600" />
			<span className="text-xs font-medium text-gray-600">
				{question.question_type === 'multiple_selection' && (
					<>
						{question.config.selection_count_type === 'exact_number' && (
							<>
								{t('questionHintsMultipleSelectionExactNumber', {
									count: question.config.max_selection_count,
								})}
							</>
						)}
						{question.config.selection_count_type === 'range' && (
							<>
								{t('questionHintsMultipleSelectionRange', {
									minCount: question.config.min_selection_count,
									maxCount: question.config.max_selection_count,
								})}
							</>
						)}
					</>
				)}
				{question.question_type === 'text' &&
					question.config.min_characters > 1 && (
						<>
							{t('questionHintsTextMinCharacters', {
								count: question.config.min_characters,
							})}
						</>
					)}
				{question.question_type === 'number' &&
					question.config.is_custom_range && (
						<>
							{t('questionHintsNumberCustomRange', {
								minNumber: question.config.min_value,
								maxNumber: question.config.max_value,
							})}
						</>
					)}
				{question.question_type === 'file_upload' && (
					<>
						{question.config.min_file_count ===
							question.config.max_file_count && (
							<>
								{t('questionHintsFileUploadExact', {
									count: question.config.max_file_count,
								})}
							</>
						)}
						{question.config.min_file_count !==
							question.config.max_file_count && (
							<>
								{t('questionHintsFileUploadBetween', {
									minFileCount: question.config.min_file_count,
									maxFileCount: question.config.max_file_count,
								})}
							</>
						)}
					</>
				)}
			</span>
		</div>
	);
}
