export const sendParentWindowMessage = message => {
	const parents = process.env.REACT_APP_IFRAME_PARENT_URI.split(' ');
	parents.forEach(parent => {
		window.parent.postMessage(message, parent);
	});
};

export const isMessageAuthentic = message => {
	const parents = process.env.REACT_APP_IFRAME_PARENT_URI.split(' ');
	return parents.includes(message.origin);
};
